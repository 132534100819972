import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { Checkbox } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <p>{`This is Checkbox component.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Checkbox} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'() => {\n  const [isChecked1, setIsChecked1] = useState(false)\n  const [isChecked2, setIsChecked2] = useState(false)\n\n  return (\n    <>\n      <Checkbox\n        checked={isChecked1}\n        onChange={() => setIsChecked1(!isChecked1)}\n      >\n        Check 1\n      </Checkbox>\n      <Checkbox\n        checked={isChecked2}\n        size={18}\n        onChange={() => setIsChecked2(!isChecked2)}\n      >\n        Check 2\n      </Checkbox>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">  
  {() => {
        const [isChecked1, setIsChecked1] = useState(false);
        const [isChecked2, setIsChecked2] = useState(false);
        return <>
        <Checkbox checked={isChecked1} onChange={() => setIsChecked1(!isChecked1)} mdxType="Checkbox">
          Check 1
        </Checkbox>
        <Checkbox checked={isChecked2} size={18} onChange={() => setIsChecked2(!isChecked2)} mdxType="Checkbox">
          Check 2
        </Checkbox>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "with-allow-message"
    }}>{`With allow message`}</h2>
    <Playground __position={2} __code={'() => {\n  const [isChecked, setIsChecked] = useState(false)\n\n  return (\n    <Checkbox\n      allowMessage={\'Hello\'}\n      checked={isChecked}\n      onChange={() => setIsChecked(!isChecked)}\n    >\n      Check 1\n    </Checkbox>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const [isChecked, setIsChecked] = useState(false);
        return <Checkbox allowMessage={'Hello'} checked={isChecked} onChange={() => setIsChecked(!isChecked)} mdxType="Checkbox">
        Check 1
      </Checkbox>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      